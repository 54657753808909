import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-tabset';
import { AccordionModule } from "ngx-accordion";
import { StickyNavModule } from 'ng2-sticky-nav';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { NgxCaptchaModule } from 'ngx-captcha';
// import { AgmCoreModule } from '@agm/core';

import { HomeComponent } from './pages/home/home.component';
import { TrainingComponent } from './pages/home/training/training.component';
import { PricingComponent } from './pages/home/pricing/pricing.component';
import { WellnessComponent } from './pages/home/wellness/wellness.component';
import { ClassesComponent } from './pages/home/classes/classes.component';
import { BannerComponent } from './pages/home/banner/banner.component';
import { SocialMediaComponent } from './pages/home/social-media/social-media.component';
import { LocationComponent } from './pages/home/location/location.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './layouts/navbar/navbar.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { PartnerComponent } from './common/partner/partner.component';
import { WorkProcessComponent } from './common/work-process/work-process.component';
import { CtaComponent } from './common/cta/cta.component';
import { NotFoundComponent } from './common/not-found/not-found.component';
import { FunfactsComponent } from './common/funfacts/funfacts.component';
import { InstagramComponent } from './common/instagram/instagram.component';
import { SubscribeComponent } from './common/subscribe/subscribe.component';
import { ProjectsDetailsComponent } from './pages/projects-details/projects-details.component';
import { ServicesDetailsComponent } from './pages/services-details/services-details.component';
import { BlogDetailsComponent } from './pages/blog-details/blog-details.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { SupportFormComponent } from './components/support-form/support-form.component';
import { environment } from 'src/environments/environment';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
/*Moment*/
import * as moment from 'moment';
/*Slick Slider*/
import { SlickCarouselModule } from 'ngx-slick-carousel';
/*range slider*/
import { NgxSliderModule } from '@angular-slider/ngx-slider';
/*PDF Viewer*/
import { PdfViewerModule } from 'ng2-pdf-viewer';
/*Custom*/
import { ClassScheduleComponent } from './pages/class-schedule/class-schedule.component';
import { CsSessionsListComponent } from './pages/class-schedule/cs-sessions-list/cs-sessions-list.component';
import { PricesComponent } from './pages/pricing/prices.component';
import { DfCarouselComponent } from './components/df-carousel/df-carousel.component';
import { GetStartedComponent } from './pages/get-started/get-started.component';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { RedirectToStoreComponent } from './pages/redirect-to-store/redirect-to-store.component';
import { UpdatePaymentInfoComponent } from './pages/update-payment-info/update-payment-info.component';
import { CafeMenuComponent } from './pages/cafe-menu/cafe-menu.component';
import { PaymentLinkComponent } from './pages/payment-link/payment-link.component';
import { RenewLinkComponent } from './pages/renew-link/renew-link.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { LoginComponent } from './components/df-login/df-login.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AccountComponent } from './pages/account/account.component';
import { FaqsPageComponent } from './pages/faqs-page/faqs-page.component';
import { SupportRequestPageComponent } from './pages/support-request-page/support-request-page.component';
import { ClassDetailsComponent } from './pages/studio/class-details/class-details.component';
import { MapComponent } from './components/map/map.component';
import { SpotGridComponent } from './components/studio/spot-grid/spot-grid.component';
import { AuthBaseComponent } from './components/auth-base/auth-base.component';
import { AlertComponent } from './components/alert/alert.component';
import { CancelReservationComponent } from './components/studio/cancel-reservation/cancel-reservation.component';
import { ConfirmReservationComponent } from './components/studio/confirm-reservation/confirm-reservation.component';
import { StudioDetailsComponent } from './pages/studio/studio-details/studio-details.component';
import { InstructorDetailsComponent } from './pages/studio/instructor-details/instructor-details.component';
import { InviteComponent } from './components/invite/invite.component';
import { CountrySelectComponent } from './components/df-country-select/df-country-select.component';
import { BookedClassComponent } from './pages/account/booked-class/booked-class.component';
import { MomentModule } from 'ngx-moment';
import { PromoBannerComponent } from './components/promo-banner/promo-banner.component';
import { PersonalInfoComponent } from './pages/account/personal-info/personal-info.component';
import { SidebarComponent } from './pages/account/sidebar/sidebar.component';
import { MyDropComponent } from './pages/account/my-drop/my-drop.component';
import { PaymentMethodsComponent } from './pages/account/payment-methods/payment-methods.component';
import { AddCardComponent } from './components/stripe/add-card/add-card.component';
import { DownloadAppComponent } from './components/download-app/download-app.component';
import { DownloadOptionsComponent } from './components/download-options/download-options.component';
import { CheckoutComponent } from './components/popups/my-drop/checkout/checkout.component';
import { StripeCheckoutComponent } from './components/popups/my-drop/stripe-checkout/stripe-checkout.component';
import { BenefitsComponent } from './components/popups/my-drop/benefits/benefits.component';
import { ChoosePlanComponent } from './components/popups/my-drop/choose-plan/choose-plan.component';
import { PurchaseComponent } from './pages/purchase/purchase.component';
import { SessionFilterComponent } from './components/popups/session-filter/session-filter.component';
import { TodaysScheduleComponent } from './pages/todays-schedule/todays-schedule.component';
import { UpgradeTimeComponent } from './components/popups/my-drop/upgrade-time/upgrade-time.component';
import { DeleteComponent } from './pages/account/delete/delete.component';
import { BuyComponent } from './pages/buy/buy.component';
import { PaymentFormComponent } from './components/popups/my-drop/payment-form/payment-form.component';
import { HttpService } from './services/common/http.service';
import { AssetsService } from './services/assets.service';
import { LogService } from './services/common/log.service';

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        HomeComponent,
        TrainingComponent,
        PricingComponent,
        WellnessComponent,
        SocialMediaComponent,
        LocationComponent,
        ClassesComponent,
        BannerComponent,
        FooterComponent,
        PartnerComponent,
        WorkProcessComponent,
        CtaComponent,
        NotFoundComponent,
        FunfactsComponent,
        InstagramComponent,
        SubscribeComponent,
        ProjectsDetailsComponent,
        ServicesDetailsComponent,
        BlogDetailsComponent,
        PrivacyPolicyComponent,
        TermsConditionsComponent,
        FaqsComponent,
        SupportFormComponent,
        PaymentFormComponent,

        ClassScheduleComponent,
        CsSessionsListComponent,
        PricesComponent,
        GetStartedComponent,

        DfCarouselComponent,
        PhoneMaskDirective,
        RedirectToStoreComponent,
        UpdatePaymentInfoComponent,
        CafeMenuComponent,
        PaymentLinkComponent,
        RenewLinkComponent,
        AppLayoutComponent,
        LoginComponent,
        AccountComponent,
        FaqsPageComponent,
        SupportRequestPageComponent,
        ClassDetailsComponent,
        MapComponent,
        SpotGridComponent,
        AuthBaseComponent,
        AlertComponent,
        CancelReservationComponent,
        ConfirmReservationComponent,
        StudioDetailsComponent,
        InstructorDetailsComponent,
        InviteComponent,
        CountrySelectComponent,
        BookedClassComponent,
        PromoBannerComponent,
        PersonalInfoComponent,
        SidebarComponent,
        MyDropComponent,
        PaymentMethodsComponent,
        AddCardComponent,
        DownloadAppComponent,
        DownloadOptionsComponent,
        CheckoutComponent,
        StripeCheckoutComponent,
        BenefitsComponent,
        ChoosePlanComponent,
        PurchaseComponent,
        BuyComponent,
        SessionFilterComponent,
        TodaysScheduleComponent,
        UpgradeTimeComponent,
        DeleteComponent,
    ],
    imports: [
        BrowserModule,
        // AgmCoreModule.forRoot({
        //     apiKey: environment.facility[environment.submerchant].googleMap.apiKey
        // }),
        AppRoutingModule,
        CommonModule,
        NgbModule,
        CarouselModule,
        BrowserAnimationsModule,
        StickyNavModule,
        NgxSmartModalModule.forRoot(),
        NgxScrollTopModule,
        TabsModule.forRoot(),
        AccordionModule,
        HttpClientModule,
        SlickCarouselModule,
        FormsModule,
        ReactiveFormsModule,
        PdfViewerModule,
        ReactiveFormsModule,
        NgxCaptchaModule,
        MomentModule,
        NgxSliderModule
    ],
    exports: [
        PhoneMaskDirective
    ],
    providers: [
        NgbActiveModal,
        {
            provide: 'moment', useValue: moment
        },
        {
            provide: 'AssetsHttpService',
            useClass: HttpService
        },
        {
            provide: AssetsService,
            useFactory: (logService: LogService, httpService: HttpService) =>
              new AssetsService(logService, httpService),
            deps: [LogService, 'AssetsHttpService']
        },
        {
            provide : HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi   : true,
        }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule { }