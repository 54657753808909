import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  private logLevel = environment.logLevel;

  log(message?: any, logLevel: number = 1, ...optionalParams: any[]): void {
    if (logLevel >= this.logLevel) {
      console.log(message, optionalParams);
    }
  }

  debug(message?: any, ...optionalParams: any[]): void {
    if (1 >= this.logLevel) {
      console.log(message, optionalParams);
    }
  }

  info(message?: any, ...optionalParams: any[]): void {
    if (2 >= this.logLevel) {
      console.log(message, optionalParams);
    }
  }

  warn(message?: any, ...optionalParams: any[]): void {
    if (3 >= this.logLevel) {
      console.warn(message, optionalParams);
    }
  }

  error(message?: any, ...optionalParams: any[]): void {
    if (4 >= this.logLevel) {
      console.error(message, optionalParams);
    }
  }

  critical(message?: any, ...optionalParams: any[]): void {
    if (5 >= this.logLevel) {
      console.error(message, optionalParams);
    }
  }
}