<div id="root">
  <div class="App-Container is-darkBackground App-Container--noAnimation flex-container justify-content-center" data-testid="checkout-container">
    <div class="App App--multiItem">
      <div class="App-Overview is-darkBackground" style="background-color: rgb(18, 18, 18);">
        <header class="Header is-darkBackground" style="background-color: rgb(18, 18, 18);">
          <div class="Header-content flex-container justify-content-space-between align-items-stretch">
            <div class="Header-business flex-item width-grow flex-container align-items-center">
              <div class="Header-merchantLogoWithLabel">
                <div class="HeaderImage HeaderImage--logo flex-item width-fixed flex-container justify-content-center align-items-center width-fixed">
                  <img alt="{{siteMeta.name}}. logo" [src]="siteMeta.lightBigLogoUrl" class="HeaderImage-img" loading="lazy"></div>
                <span class="Header-businessLink-label Text Text-color--gray800 Text-fontSize--14 Text-fontWeight--500">Back</span>
              </div>
            </div>
            <div class="Header-actions flex-item width-fixed">
              <button class="Button Header-viewDetailsButton Button--link Button--sm" type="button">
                <div class="flex-container justify-content-flex-end align-items-center">
                  <svg class="InlineSVG Icon Button-Icon Button-Icon--right Icon--sm Icon--square" focusable="false" viewBox="0 0 12 12">
                    <path d="M10.193 3.97a.75.75 0 0 1 1.062 1.062L6.53 9.756a.75.75 0 0 1-1.06 0L.745 5.032A.75.75 0 0 1 1.807 3.97L6 8.163l4.193-4.193z" fill-rule="evenodd"></path>
                  </svg>
                  <div class="Header-detailsLabel" style="position: unset; opacity: 1; transform: none;"><span class="Text Text-color--default Text-fontSize--13 Text-fontWeight--400 Text--truncate">Details</span></div>
                </div>
              </button>
            </div>
          </div>
        </header>
        <div class="OrderSummaryColumn" data-testid="order-summary-column">
          <div data-testid="product-summary" class="ProductSummary is-clickable">
            <div class="ProductSummary-productImageContainer is-clickable" data-testid="product-summary-product-image" *ngIf="pack.Image">
              <div class="ProductImage-container" data-testid="product-image">
                <img [src]="pack.Image" [alt]="menuPack.Name" class="ProductImage-image" fetchpriority="high">
              </div>
            </div>
            <div class="ProductSummary-info is-clickable is-darkBackground">
              <span class="ProductSummary-name Text Text-color--gray500 Text-fontSize--16 Text-fontWeight--500" data-testid="product-summary-name">Pay {{siteMeta.name}}.</span>
              <div class="ProductSummary-amountsContainer false">
                <div class="ProductSummary-totalsRead" style="opacity: 1;">
                  <div class="ProductSummary-totalAmountContainer is-clickable" style="opacity: 1;">
                    <span class="ProductSummary-totalAmount Text Text-color--default Text-fontWeight--600 Text--tabularNumbers" id="ProductSummary-totalAmount" data-testid="product-summary-total-amount">
                      <span class="CurrencyAmount">US{{formatCurrency((menuPack.Price / 100))}}</span>
                    </span>
                  </div>
                  <div class="AnimateSinglePresence">
                    <div class="AnimateSinglePresenceItem">
                      <div>
                        <div class="ProductSummary-amountsDescriptions" data-testid="product-summary-description"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ProductSummary-buttonsContainer" data-testid="product-summary-buttons"></div>
            </div>
          </div>
          <section data-testid="order-details" class="OrderDetails App-Overview-OrderDetails my6">
            <ul class="OrderDetails-items">
              <li class="OrderDetails-item" style="opacity: 1; transform: scale(1); height: auto; margin-bottom: 16px;">
                <div class="LineItem flex-container align-items-flex-start">
                  <div class="LineItem-imageContainer mr4 flex-item width-fixed" data-testid="line-item-image" *ngIf="pack.Image">
                    <img [src]="pack.Image" [alt]="menuPack.Name" class="LineItem-image"></div>
                  <div class="flex-container justify-content-space-between align-items-baseline wrap-wrap width-grow">
                    <div class="flex-column-break" style="order: 2;"></div>
                    <div class="flex-column-break" style="order: 5;"></div>
                    <div class="LineItem-productName flex-item width-auto" data-testid="line-item-product-name" style="order: 0;">
                      <span class="Text Text-color--default Text-fontSize--14 Text-fontWeight--500">
                        <div class="ExpandableText false">
                          <div data-testid="" class="ExpandableText--truncated" style="-webkit-line-clamp: 2;">{{menuPack.Name}}</div>
                        </div>
                      </span>
                    </div>
                    <div class="flex-container direction-column wrap-wrap" style="width: 100%; order: 3;">
                      <div class="LineItem-productDescription flex-item width-12" style="order: -1;">
                        <div class="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">
                          <div class="ExpandableText false">
                            <div data-testid="line-item-product-description" class="ExpandableText--truncated" style="-webkit-line-clamp: 2;">
                              {{pack.Description}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="flex-container direction-row wrap-wrap">
                        <div class="LineItem-description flex-item width-grow" style="order: 3;"><span class="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400"></span></div>
                        <div class="LineItem-amountDetail flex-item width-auto flex-container align-items-flex-end width-auto" data-testid="line-item-amount-detail" style="order: 4;">
                          <div class="flex-item width-auto flex-item-align-right">
                            <div class="flex-container Placeholder" style="opacity: 1;"><span class="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">
                              <span class="CurrencyAmount">US{{formatCurrency((menuPack.Price / 100))}}</span> {{getTermDuration()}}</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="LineItem-tierBreakdown flex-item width-auto flex-item-align-left" style="order: 6;">
                      <div class="flex-container" data-testid="line-item-tiers"></div>
                    </div>
                    <div class="flex-container Placeholder" style="opacity: 1;">
                      <div class="ml2 flex-item width-fixed" data-testid="line-item-total-amount" style="order: 1;"><span class="Text Text-color--default Text-fontSize--14 Text-fontWeight--500 Text--tabularNumbers">
                        <span class="CurrencyAmount">US{{formatCurrency((menuPack.Price / 100))}}</span></span></div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="OrderDetails-footer">
              <div class="is-indented">
                <div class="OrderDetailsFooter-subtotal flex-container justify-content-space-between">
                  <span class="Text Text-color--default Text-fontSize--14 Text-fontWeight--500">Subtotal</span>
                  <div class="flex-container Placeholder" style="opacity: 1;"><span class="Text Text-color--default Text-fontSize--14 Text-fontWeight--600 Text--tabularNumbers" id="OrderDetailsFooter-SubtotalAmount">
                    <span class="CurrencyAmount">US{{formatCurrency((menuPack.Price / 100))}}</span></span></div>
                </div>
                <div class="OrderDetailsFooter-subtotalItems flex-container direction-column">
                  <div class="FadeWrapper"></div>
                  <div>
                    <div class="OrderDetailsSubtotalItem flex-container justify-content-space-between">
                      <div>
                        <div class="flex-container">
                          <span class="OrderDetails-subtotalItemLabel-Text Text Text-color--gray900 Text-fontSize--14">
                            Tax
                          </span>
                        </div>
                      </div>
                      <div class="flex-container Placeholder" style="opacity: 1;"><span class="Text Text-color--gray400 Text-fontSize--14 Text--tabularNumbers">
                        <span class="CurrencyAmount">US{{formatCurrency((taxAmount / 100))}}</span></span></div>
                    </div>
                  </div>
                </div>
                <div class="OrderDetails-total flex-container justify-content-space-between">
                  <span><span class="Text Text-color--default Text-fontSize--14 Text-fontWeight--500">Total due today</span></span>
                  <div class="flex-container Placeholder" style="opacity: 1;"><span class="Text Text-color--default Text-fontSize--16 Text-fontWeight--600 Text--tabularNumbers" id="OrderDetails-TotalAmount">
                    <span class="CurrencyAmount">US{{formatCurrency(((menuPack.Price / 100) + (taxAmount / 100)))}}</span></span></div>
                </div>
                <div class="OrderDetails-upcomingPayment flex-container justify-content-space-between">
                  <div class="LineItem-productDescription flex-item width-12" style="order: -1;">
                    <div class="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">
                      <div class="ExpandableText false">
                        <div data-testid="line-item-product-description" class="ExpandableText--truncated" style="-webkit-line-clamp: 2;">
                          Upcoming payments
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="OrderDetailsFooter-otherFee flex-container justify-content-space-between">
                  <span class="Text Text-color--default Text-fontSize--14 Text-fontWeight--500">Annual Maintenance Fee</span>
                  <div class="flex-container Placeholder" style="opacity: 1;"><span class="Text Text-color--default Text-fontSize--14 Text-fontWeight--600 Text--tabularNumbers" id="OrderDetailsFooter-SubtotalAmount">
                    <span class="CurrencyAmount">US{{formatCurrency((49.99))}}</span></span></div>
                </div>
                <div class="LineItem-productDescription flex-item width-12" style="order: -1;">
                  <div class="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">
                    <div class="ExpandableText false">
                      <div data-testid="line-item-product-description" class="ExpandableText--truncated" style="-webkit-line-clamp: 2;">
                        Due 6/1/2025
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="App-Payment">
        <main>
          <div class="CheckoutPaymentForm">
            <div style="height: 36.7969px;">
              <div>
                <div class="PaymentRequestOrHeader" style="display: inherit; height: auto;">
                  <div class="AnimateSinglePresence">
                    <div class="AnimateSinglePresenceItem">
                      <div>
                        <div class="PaymentHeaderContainer" style="display: block; opacity: 1;">
                          <h2 class="PaymentHeader Text Text-color--gray600 Text-fontSize--16 Text-fontWeight--500">Contact information</h2>
                        </div>
                        <div class="ButtonAndDividerContainer" style="display: none; opacity: 0; height: 0px;">
                          <div class="ButtonContainer">
                            <div class="ButtonWrapper">
                              <div class="ButtonWrapper"></div>
                            </div>
                          </div>
                          <div></div>
                          <div class="Divider">
                            <hr>
                            <p class="Divider-Text Text Text-color--gray400 Text-fontSize--14 Text-fontWeight--400"><span class="DividerText">Or pay another way</span></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form class="PaymentForm-form" novalidate="" *ngIf="paymentForm" [formGroup]="paymentForm" (ngSubmit)="onSubmit()">
              <input type="hidden" formControlName="MenuPackId" />
              <input type="hidden" formControlName="Amount" />
              <input type="hidden" formControlName="Currency" />
              <div class="" style="position: relative;">
                <div class="App-Global-Fields flex-container spacing-16 direction-column wrap-wrap">
                  <div class="flex-item width-12">
                    <div class="FormFieldGroup" data-qa="FormFieldGroup-email">
                      <div class="FormFieldGroup-labelContainer flex-container justify-content-space-between">
                        <label for="email-fieldset">
                          <span class="Text Text-color--gray600 Text-fontSize--13 Text-fontWeight--500">Contact information</span></label>
                        <div style="opacity: 1; transform: none;"></div>
                      </div>
                      <fieldset class="FormFieldGroup-Fieldset" id="firstName-fieldset">
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="FormFieldGroup-container">
                              <div class="FormFieldGroup-child FormFieldGroup-child--width-12 FormFieldGroup-childLeft FormFieldGroup-childRight FormFieldGroup-childTop">
                                <div class="form-floating">
                                  <input #email type="text" name="FirstName" formControlName="FirstName" class="form-control" id="FirstName" placeholder="FirstName" required autofocus (focusout)="validateField('FirstName')" />
                                  <label for="FirstName">First Name</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="FormFieldGroup-container">
                              <div class="FormFieldGroup-child FormFieldGroup-child--width-12 FormFieldGroup-childLeft FormFieldGroup-childRight FormFieldGroup-childTop">
                                <div class="form-floating">
                                  <input #email type="text" name="LastName" formControlName="LastName" class="form-control" id="LastName" placeholder="LastName" required autofocus (focusout)="validateField('LastName')" />
                                  <label for="LastName">Last Name</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset class="FormFieldGroup-Fieldset" id="email-fieldset">
                        <div class="FormFieldGroup-container">
                          <div class="FormFieldGroup-child FormFieldGroup-child--width-12 FormFieldGroup-childLeft FormFieldGroup-childRight FormFieldGroup-childTop">
                            <div class="form-floating">
                              <input #email type="email" name="email" formControlName="Email" class="form-control" id="email" placeholder="Email" required autofocus (focusout)="validateField('Email')" />
                              <label for="email">Email</label>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset class="FormFieldGroup-Fieldset" id="phoneNumber-fieldset">
                        <div class="FormFieldGroup-container">
                          <div class="FormFieldGroup-child FormFieldGroup-child--width-12 FormFieldGroup-childLeft FormFieldGroup-childRight FormFieldGroup-childBottom">
                            <div class="FormFieldInput">
                              <div class="PhoneNumberInput">
                                <div class="input-group has-validation">
                                  <span class="input-group-text" (click)="country.open(countryCode)">
                                    <img src="{{flag()}}" class="flag">
                                    <span class="px-1">{{dialCode}}</span>
                                  </span>
                                  <div class="form-floating">
                                    <input #phone type="tel" name="phone" formControlName="PhoneNumber" class="form-control" id="phone" placeholder="Mobile Number" required autofocus (focusout)="validateField('PhoneNumber')" />
                                    <label for="phone">Phone Number</label>
                                  </div>
                                  <div class="invalid-feedback">
                                    Please enter a phone number.
                                  </div>
                                </div>
                                <div class="PhoneNumberInput-errorMessageAnimation" style="display: none; height: 0px; opacity: 0; pointer-events: none;"><span class="FieldError Text Text-color--red Text-fontSize--13"><span aria-hidden="true"></span></span></div>
                              </div>
                            </div>
                          </div>
                          <div class="FieldError-container" style="opacity: 0; height: 0px; margin-top: 0px;"><span class="FieldError Text Text-color--red Text-fontSize--13"><span aria-hidden="true"></span></span></div>
                        </div>
                        <div class="FieldError-container" style="opacity: 0; height: 0px; margin-top: 0px;"><span class="FieldError Text Text-color--red Text-fontSize--13"><span aria-hidden="true"></span></span></div>
                      </fieldset>
                      <fieldset class="FormFieldGroup-Fieldset" id="email-notification-fieldset">
                        <div class="FormFieldGroup-container">
                          <div class="FormFieldGroup-child FormFieldGroup-child--width-12 FormFieldGroup-childLeft FormFieldGroup-childRight FormFieldGroup-childTop">
                            <div class="form-floating">
                              <div class="CheckboxField">
                                <div class="Checkbox">
                                  <div class="Checkbox-InputContainer">
                                    <input #emailNotification type="checkbox" name="emailNotification" formControlName="EmailNotification" id="emailNotification" placeholder="Email Notification" required autofocus class="Checkbox-Input" (change)="onTermsOfServiceChange($event)" />
                                  </div>
                                  <div><label for="emailNotification"><span class="Checkbox-Label Text Text-color--gray900 Text-fontSize--13 Text-fontWeight--500">Email me with news and updates</span></label></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset class="FormFieldGroup-Fieldset" id="sms-notification-fieldset">
                        <div class="FormFieldGroup-container">
                          <div class="FormFieldGroup-child FormFieldGroup-child--width-12 FormFieldGroup-childLeft FormFieldGroup-childRight FormFieldGroup-childTop">
                            <div class="form-floating">
                              <div class="CheckboxField">
                                <div class="Checkbox">
                                  <div class="Checkbox-InputContainer">
                                    <input #smsNotification type="checkbox" name="smsNotification" formControlName="SmsNotification" id="smsNotification" placeholder="Sms Notification" required autofocus class="Checkbox-Input" (change)="onSmsNotificationChange($event)" />
                                  </div>
                                  <div><label for="smsNotification"><span class="Checkbox-Label Text Text-color--gray900 Text-fontSize--13 Text-fontWeight--500">Text me with news and updates</span></label></div>
                                </div>
                              </div>
                              <div id="smsNotificationInfo" *ngIf="isSmsNotificationVisible"><label for="smsNotification"><span class="Checkbox-Label Text Text-color--gray600 Text-fontSize--13 Text-fontWeight--500">By signing up via text, 
                                you agree to receive recurring automated marketing messages, including cart reminders, at the phone number provided. 
                                Consent is not a condition of purchase. Reply STOP to unsubscribe. Reply HELP for help. Message frequency varies.
                                Msg & data rates may apply. View our <a class="Link CheckoutFooter-link Link--checkout--secondary" [href]="siteMeta.privacyPolicyUrl" target="_blank" rel="noopener">Privacy Policy</a>.</span></label></div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
                <!-- <div class="loading-bg justify-content-center" *ngIf="!isLoading"></div>
                <div class="loading justify-content-center" *ngIf="!isLoading"><img src="assets/images/loading.gif" /></div> -->
                <div class="flex-item width-12">
                  <h2 class="PaymentMethod-Heading Text Text-color--gray800 Text-fontSize--16 Text-fontWeight--500">Payment method</h2>
                </div>
                <div *ngIf="paymentMethods?.length">
                  <span class="Text Text-color--gray600 Text-fontSize--13 Text-fontWeight--500">Select an Existing Payment Method</span>
                  <div *ngFor="let method of paymentMethods" class="paymentMethodRow">
                    <label>
                      <input 
                        type="radio" 
                        name="paymentMethod" 
                        [value]="method.Id" 
                        (change)="selectPaymentMethod(method.Id)">
                      <ng-container *ngIf="method.Card">
                        {{ toTitleCase(method.Card.Brand) }} ending in {{ method.Card.Last4 }}
                      </ng-container>
                      <ng-container *ngIf="method.UsBankAccount">
                        Bank Account ({{ method.UsBankAccount.BankName }}, ending in {{ method.UsBankAccount.Last4 }})
                      </ng-container>
                    </label>
                    
                  </div>
                  <div class="paymentMethodRow">
                    <label>
                      <input 
                        type="radio" 
                        name="paymentMethod" 
                        value="none" 
                        checked="checked"
                        (change)="selectPaymentMethod('none')">
                      <ng-container>
                        Add new payment method
                      </ng-container>
                    </label>
                  </div>
                </div>
                <div class="PaymentMethodForm" style="opacity: 1; height: auto;">
                  <div id="payment-element"></div>
                </div>
                <div class="PaymentForm-confirmPaymentContainer mt4 flex-item width-grow">
                  <div class="ConfirmPayment">
                    <div class="FormFieldCheckbox TermsOfServiceConsentCheckbox mb4">
                      <div class="CheckboxField">
                        <div class="Checkbox">
                          <div class="Checkbox-InputContainer">
                            <input id="termsOfServiceConsentCheckbox" name="termsOfServiceConsentCheckbox" type="checkbox" class="Checkbox-Input" (change)="onTermsOfServiceChange($event)" />
                          </div>
                          <div><label for="termsOfServiceConsentCheckbox"><span class="Checkbox-Label Text Text-color--gray600 Text-fontSize--13 Text-fontWeight--500">I agree to {{siteMeta.name}}.'s <a class="Link TermsOfServiceConsentCheckbox-customMessageLink Link--checkout--secondary" href="/terms" target="_blank" rel="noopener">Terms of Service</a> and <a class="Link TermsOfServiceConsentCheckbox-customMessageLink Link--checkout--secondary" href="/privacy" target="_blank" rel="noopener">Privacy Policy</a></span></label></div>
                        </div>
                      </div>
                    </div>
                    <div class="AnimateSinglePresence"></div>
                    <div class="flex-item width-12"></div>
                    <div class="flex-item width-12">
                      <button class="SubmitButton" type="submit" [disabled]="isButtonDisabled" data-testid="hosted-payment-submit-button" style="background-color: rgb(0, 116, 212); color: rgb(255, 255, 255);">
                        Pay
                      </button>
                    </div>
                    <div class="flex-item width-12">
                      <div class="ConfirmPayment-PostSubmit">
                        <div class="ConfirmTerms">
                          <div class="AnimateSinglePresence"></div>
                          <div class="AnimateSinglePresence">
                            <div class="AnimateSinglePresenceItem ConfirmPaymentTerms">
                              <div>
                                <div class="ConfirmTerms Text Text-color--gray600 Text-fontSize--13" data-testid="ConfirmPaymentTermsText">
                                  <div class="AnimateSinglePresence"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="AnimateSinglePresence"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </main>
        <footer class="App-Footer Footer">
          <div class="Footer-PoweredBy">
            <a class="Link Link--primary" href="https://tech.dropfitness.com" target="_blank" rel="noopener">
              <div class="Footer-PoweredBy-Text Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">
                Powered by 
                <span>
                  <img class="logo-img" [src]="'/assets/images/icon/drop_logo.png'" width="15" />
                </span>
              </div>
            </a>
          </div>
          <div class="CheckoutFooter-links flex-container direction-row">
            <a class="Link CheckoutFooter-link Link--checkout--secondary" [href]="siteMeta.termsUrl" target="_blank" rel="noopener"><span class="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">Terms</span></a>
            <a class="Link CheckoutFooter-link Link--checkout--secondary" [href]="siteMeta.privacyPolicyUrl" target="_blank" rel="noopener"><span class="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">Privacy</span></a></div>
        </footer>
      </div>
    </div>
  </div>
  <df-country-select #country (select)="changeCountry($event)"></df-country-select>
</div>