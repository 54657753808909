declare var $: any;
//system
import { Component, Input, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'df-purchase-complete',
  templateUrl: './purchase-complete.component.html',
  styleUrls: ['./purchase-complete.component.scss']
})
export class PurchaseCompleteComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public packPhoto : string = "";
  @Input() public heading : string = "";
  @Input() public subHeading : string = "";

  packPhotoStr: string = "";
  headingStr: string = "Purchase Complete!";
  subHeadingStr: string = "Congrats! We can't wait to meet you! You will receive an email confirmation for your purchase and we will keep you in the loop on all the latest news with the opening!";
  constructor(
    public activeModal: NgbActiveModal
  ) {
    
  }

  ngOnInit() {
    this.packPhotoStr = this.packPhoto;
    this.headingStr = this.heading;
    this.subHeadingStr = this.subHeading;
  }

  ngAfterViewInit() {

  }

  closeModal() {
    this.activeModal.close();
  }

  ngOnDestroy(): void {
  }
}

