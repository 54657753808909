<div class="location-area pb-70">
  <div class="container">
    <div class="row">
      <div class="col df-location">
        <div class="container location-info pt-2">
          <div class="row mb-2 pb-2">
            <div class="col-2 location-icon text-center py-2">
              <img src="/assets/images/svg/icon_location.svg" alt="Location" title="Location" />
            </div>
            <div class="col-10">
              <div class="title py-2">Drop Fitness - Montvale</div>
              <div>2 Chestnut Ridge Road</div>
              <div>Montvale, NJ 07645</div>
              <div class="pt-2">Phone: <a href="tel:2013756806">(201) 375-6806</a></div>
            </div>
          </div>
          <div class="row border-top mb-2 pt-2">
            <div class="col-2 location-icon text-center py-2">
              <img src="/assets/images/svg/icon_hours.svg" alt="Hours" title="Hours" />
            </div>
            <div class="col-10">
              <div class="title py-2">Hours of Operation</div>
              <div class="row g-0">
                <div class="col">Mon - Fri:</div>
                <div class="col">5AM - 9PM</div>
              </div>
              <div class="row g-0 pb-2">
                <div class="col">Sat - Sun:</div>
                <div class="col">7AM - 5PM</div>
              </div>
            </div>
          </div>
          <div class="row border-top">
            <div class="col-2"></div>
            <div class="col-10 py-3">
              <a class="btn btn-primary rounded-pill px-4 py-2 rounded-pill-btn" style="font-size: 14px;" target="_blank" href="https://www.google.com/maps/place/Drop+Fitness/@41.0471936,-74.0745178,17z">GET DIRECTIONS</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>