import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, throwError, switchMap, finalize, lastValueFrom, Observable, of, Subject, takeUntil, tap } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { MemberService } from 'src/app/services/member.service';
import { CreditMemberCurrencyRequest, MemberCurrency, MemberDatum, MemberModel, Registration, RegistrationProfile } from 'src/app/models/member';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import parsePhoneNumber from 'libphonenumber-js';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import { CountryOptions } from '../../models/country-codes';
import { environment } from 'src/environments/environment';
import { Utils } from 'src/app/services/common/utils';
import { PersonModel } from 'src/app/models/common-models';
import { Router } from '@angular/router';

declare var segment: any;

@Component({
  selector: 'df-login',
  templateUrl: './df-login.component.html',
  styleUrls: ['./df-login.component.scss']
})
export class LoginComponent implements OnInit {
  profileForm : FormGroup;
  phoneForm : FormGroup;
  submitted: boolean = false;
  loginStep: string = 'phone';
  dialCode = '+1';
  phoneValue = '';
  phoneNumber = '';
  codeValue = '';
  registration!: Registration;
  memberInfo!: MemberModel;
  personInfo!: PersonModel;
  isNew = false;
  countryCode = 'us';

  @Input()
  modal: any;
  @Input()
  title: string = 'Welcome Back';
  @Input()
  loginText: string = 'Please enter your mobile number to verify or create an account.';

  @Output() loggedIn: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('login', { read: TemplateRef }) login: TemplateRef<any> | undefined;
  
  private readonly _unsubscribe: Subject<any> = new Subject();
  mfaRequest: Object | undefined;
 
	separateDialCode = false;
  context: any = undefined;

  showGetStarted: boolean = false;
  hasProfile: boolean = false;

  constructor(private modalService: NgbModal
    , private _auth: AuthService
    , private _userService: UserService
    , private _memberService: MemberService
    , private _router: Router
    ) {
      this.profileForm = new FormGroup({
        FirstName: new FormControl('', Validators.required),
        LastName: new FormControl('', Validators.required),
        Email: new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$')]),
        BirthDate: new FormControl('', Validators.required),
        Gender: new FormControl('', Validators.required),
        ZipCode: new FormControl('', Validators.required),
      });
    this.phoneForm = new FormGroup({
      Phone: new FormControl('', Validators.required),
    });
    }

  ngOnInit(): void {
    this.personInfo = new PersonModel();
  }

  public open(metaData: any = null, phone = '') {
    this.context = metaData;
    this.showGetStarted = !!phone;
    this.hasProfile = false;
    setTimeout(() => {
      // preload country flags
      CountryOptions.countries.forEach(c => {
        let img = new Image();
        img.src = `${CountryOptions.flagsUrl}${c.flag_4x3}`;
      })
    })
    this.openModal(this.login, phone);
  }

  public dismiss() {
    this.modalService.dismissAll();
  }

  public openModal(modal: any, phone = ''): void {
    this.dialCode = '+1';
    this.countryCode = 'us';
    this.phoneNumber = phone;
    this.phoneValue = phone;
    this.codeValue = '';
    this.isNew = false;
    
    this.loginStep = 'phone';
    this.submitted = false;
    let modalRef = this.modalService.open(modal, {
      ariaLabelledBy: 'modal-basic-title', 
      // size: 'lg',
      centered: true,
      fullscreen: window.innerWidth <= 768,
      // windowClass: 'custom-class'
    });
    modalRef.shown.subscribe(() => {
      if (phone) {
        this.phoneForm.setValue({ Phone: phone });
        this.enterPhone();
      }
    });
  }

  async enterPhone() {
    segment.track(segment.TRACK_EVENTS.regStep1, {
      phone: this.dialCode + this.phoneNumber
    })
    this.submitted = true;
    this._auth.login(this.phoneNumber, this.dialCode)
      .pipe(
        takeUntil(this._unsubscribe),
        catchError((err: any, caught: Observable<any>) => {
          if (err.status === 428) {
            this.hasProfile = true;
            this.loginStep = 'verify';
            this.submitted = false;
            return of([]);
          } else if (err.status === 401) {
            this.isNew = true;
            return this.signupMember(this.phoneNumber, this.dialCode).pipe(
              catchError((signupErr: any) => {
                // Handle error from signupMember if needed
                this.submitted = false;
                return throwError(signupErr);
              })
            );
          }
          return throwError(err);
        }),
        finalize(() => {
        })
      )
      .subscribe(async (jwt: any) => {
        if (jwt && jwt.access_token) {
          this.setToken(jwt);
          this.registration = await this._memberService.getCompeletedRegistrationSteps();
          if (!Utils.hasStepCompleted(this.registration.CompletedSteps, 'phone')) {
            await this.enterCode();
          } else if (!Utils.hasStepCompleted(this.registration.CompletedSteps, 'profile')) {
            this.memberInfo = await this._memberService.getMember();
            this.fetchUserInfo(
              () => {
                // Success callback
              },
              () => {
                this.loginStep = 'register';
                this.submitted = false;
              }
            );
          } else {
            this.loginStep = 'verify';
            this.submitted = false;
            await this.enterCode();
          }
          
        }
      });
  
    return true;
  }

  phoneChange(input: any) {
    let value = input.value;
    let phone = parsePhoneNumber(value, <CountryCode>this.countryCode.toUpperCase());
    if (phone?.nationalNumber && (isValidPhoneNumber(phone.nationalNumber, phone.country) || !environment.production && value?.length >= 10)) {
      input.value = phone.formatNational();
      this.phoneValue = phone.number;
      this.phoneNumber = phone.nationalNumber;
      this.dialCode = `+${phone.countryCallingCode}`;
    } else {
      // if (!isValidPhoneNumber(phone?.nationalNumber || '', phone?.country)) {
      //   console.log('Invalid number: ', phone?.nationalNumber || '');
      // }
      this.phoneValue = '';
      this.phoneNumber = '';
    }
  }

  formattedPhoneNumber(): string {
    if (this.phoneValue) {
      let phone = parsePhoneNumber(this.phoneValue, <CountryCode>this.countryCode.toUpperCase());
      if (phone?.isValid)
        return `${this.dialCode} ${phone.formatNational()}`;
    }
    return this.phoneValue;
  }

  signupMember(phone: string, dialCode: string): Observable<any> {
    return this._auth.signup(phone, dialCode)
      .pipe(
        takeUntil(this._unsubscribe),
        tap(async (jwt: any) => {
          if (jwt && jwt.access_token) {
            this.setToken(jwt);
            this.isNew = true;
          }
          if (this.phoneNumber.startsWith('555')) {
            this.submitted = false;
            this.loginStep = 'verify';
          }
        }),
        catchError((err: any) => {
          if (err.status === 428) {
            this.loginStep = 'verify';
            this.submitted = false;
            return of([]);
          }
          this.submitted = false;
          return throwError(err);
        })
      );
  }

  hasPhone(): boolean {
    return !!this.phoneNumber;
  }

  phoneKeyPress(e: any) {
    if (e.keyCode == 13) this.enterPhone();
  }

  codeChange(value: string) {
    this.codeValue = value;
  }

  async codeKeyPress(e: any) {
    if (e.keyCode == 13) await this.enterCode();
  }

  setToken(jwt: any) {
    this._auth.setProp('token', jwt.access_token);
  }

  fetchUserInfo(existsCallback: Function = () => { }, newUserCallback: Function = () => { }) {
    this._userService.fetchUser()?.pipe(
      takeUntil(this._unsubscribe),
      tap((user: any) => {
        // if any of these is missing - assume new member
        if (!user.Email || !user.FirstName || !user.LastName) {
          this.isNew = true;
          this.hasProfile = false;
          this.submitted = false;
          newUserCallback();
          return;
        }
        this._auth.setProp('user', user);
        this.fetchMemberInfo();
        this.showWelcome();
        this.submitted = false;

        segment.identify();

        existsCallback();
      }),
      catchError(async (err: any) =>{
        if (err && err.status === 404) {
          this.registration = await this._memberService.getCompeletedRegistrationSteps();
          this.isNew = true;
          this.hasProfile = false;
          this.submitted = false;
          newUserCallback();
          return;
        }
        return err;
      })
    )
    .subscribe();
  }

  showWelcome() {
    this.loginStep = 'welcome';
    setTimeout(() => {
      this.showGetStarted
        ? (
          !this.hasProfile
            ? this.loginStep = 'get-started'
            : this.viewSchedule()
        )
        : this.dismiss();
      if (this.loggedIn)
        this.loggedIn.emit(true);
    }, 1000);
  }

  viewSchedule() {
    this.dismiss();
    this._router.navigateByUrl('/class-schedule');
  }

  fetchMemberInfo() {
    this._userService.fetchMember()?.pipe(
      takeUntil(this._unsubscribe),
      tap((member: any) => {
        if (member != undefined) {
          this._auth.setProp('member', member);
        
          this._userService.fetchAvatar(member.Id)?.pipe(
            takeUntil(this._unsubscribe),
            tap((avatar: any) => {
              this.submitted = false;
              if(avatar)
              {
                this._auth.setProp('avatar', avatar);
              } else {
                // this._auth.setProp('avatar', {UploadedUrl: '/assets/images/df/profile.png'});
              }
            }),
            catchError((err: any) => {
              // this._auth.setProp('avatar', {UploadedUrl: '/assets/images/df/profile.png'});
              console.log('Can\'t fetch avatar');
              return err;
            })
          )
          .subscribe();
        }
      })
    )
    .subscribe();
  }

  async setRegistrationPhone() {
    if (!this.isNew && this.registration && this.registration.Id) {
      this.fetchUserInfo();
      return;
    }
    await this._auth.registerPhone(this.phoneNumber, this.dialCode);

    this.fetchUserInfo(
      () => {
        // Success callback
      },
      () => {
        this.loginStep = 'register';
        this.submitted = false;
      }
    );
  }

  async enterCode() {
    this.submitted = true;
    if (this.phoneNumber.startsWith('555')) {
      segment.track(segment.TRACK_EVENTS.regStep2, {
        phone: this.dialCode + this.phoneNumber
      })
      this.registration = await this._memberService.getCompeletedRegistrationSteps();
      if (!Utils.hasStepCompleted(this.registration.CompletedSteps, 'phone')) {
        this.isNew = true;
        await this.setRegistrationPhone();
        this.memberInfo = await this._memberService.getMember();
      }else if (!Utils.hasStepCompleted(this.registration.CompletedSteps, 'profile')) {
        this.fetchUserInfo(
          () => {
            // Success callback
          },
          () => {
            this.loginStep = 'register';
            this.submitted = false;
          }
        );
      } else {
        this.fetchUserInfo(
          () => {
            // Success callback
          },
          () => {
            this.submitted = false;
            this.showWelcome();
          }
        );
        
      }
    }
    else {
      this._auth.verify(this.phoneNumber, this.codeValue, this.dialCode)
        .pipe(
          takeUntil(this._unsubscribe),
          tap(async (jwt: any) => {
            if (jwt && jwt.access_token) {
              segment.track(segment.TRACK_EVENTS.regStep2, {
                phone: this.dialCode + this.phoneNumber
              })
              this.setToken(jwt);
              await this.setRegistrationPhone();
              this.fetchMemberInfo();
              if (!this.registration) {
                this.registration = await this._memberService.getCompeletedRegistrationSteps();
              }

              if (!Utils.hasStepCompleted(this.registration.CompletedSteps, 'profile')) {
                this.fetchUserInfo(
                  () => {
                    // Success callback
                  },
                  () => {
                    this.loginStep = 'register';
                    this.submitted = false;
                  }
                );
                return;
              }
              
            }
            this.submitted = false;
            this.dismiss();
            // this.showWelcome();
          }),
          catchError((err: any) => {
            this.loginStep = 'error';
            this.submitted = false;
            return err;
          })
        )
        .subscribe();
    }
    return true;
  }

  async registerMember() {
    this.isNew = false;
    this.personInfo.DialCode = this.dialCode;
    this.personInfo.PhoneNumber = this.phoneNumber;
    this.personInfo.Gender = this.personInfo.GenderStr;
    this.submitted = true;

    if (!this.memberInfo)
      this.memberInfo = await this._memberService.getMember();

    if (this.profileForm.valid) {
      this.personInfo.FirstName = this.profileForm.controls['FirstName'].value;
      this.personInfo.LastName = this.profileForm.controls['LastName'].value;
      this.personInfo.Email = this.profileForm.controls['Email'].value;
      this.personInfo.GenderStr = this.profileForm.controls['Gender'].value;
      this.personInfo.Gender = (
        this.personInfo.GenderStr === 'Female' ? '1' : (
          this.personInfo.GenderStr === 'Male' ? '2' : (
            this.personInfo.GenderStr === 'Non-binary' ? '3' : (
              this.personInfo.GenderStr === 'Other' ? '4' : ''
            )
          )
        )
      );
      this.personInfo.BirthDate = this.profileForm.controls['BirthDate'].value;
      this.personInfo.ZipCode = this.profileForm.controls['ZipCode'].value;

      const registrationProfile : RegistrationProfile = {
        FirstName: this.profileForm.controls['FirstName'].value,
        LastName: this.profileForm.controls['LastName'].value,
        EmailAddress: this.profileForm.controls['Email'].value,
        Gender: this.personInfo.Gender,
        BirthDate: this.profileForm.controls['BirthDate'].value,
        ZipCode: this.profileForm.controls['ZipCode'].value,
        RegistrationId: this.registration.Id,
        RegistrationGuid: this.registration.Guid,
        CreatedAt: new Date('0001-01-01 00:00:00.000000'),
        CreatedBy: "00000000-0000-0000-0000-000000000000",
        Guid: "00000000-0000-0000-0000-000000000000",
        Id: 0,
      };


    segment.track(segment.TRACK_EVENTS.regStep3, {
      first_name: this.personInfo.FirstName,
      last_name: this.personInfo.LastName,
      email: this.personInfo.Email,
      dob: this.personInfo.BirthDate,
      gender: this.personInfo.GenderStr,
      zip_code: this.personInfo.ZipCode,
    })

      await this._auth.registerProfile(registrationProfile);
      this._auth.updateUser(this.personInfo).subscribe(data => {
        this.fetchUserInfo();
        // this.showWelcome();
        this.addTrialCredits();
        this.addMemberDatumEntries();
        this.submitted = false;
      });
      this.submitted = false;
      this.dismiss();
      return true;
    } else {
      // Mark all fields as touched to display validation errors
      Object.values(this.profileForm.controls).forEach(control => control.markAsTouched());
      this.submitted = false;
      return false;
    }
  }
      
  private addTrialCredits() {
    const creditStudioMemberCurrencyRequest: CreditMemberCurrencyRequest = {
      MemberId: this.memberInfo.Id,
      CurrencyId: 1,
      PackId: 1,
      MenuPackId: 1,
      Amount: 1,
      StartAt: '0001-01-01 00:00:00.000000',
      EndAt: '0001-01-01 00:00:00.000000',
      Comment: 'TRIAL CREDIT',
      BookingId: null,
      BookingGuid: '00000000-0000-0000-0000-000000000000',
      BookingDate: '0001-01-01 00:00:00.000000',
      ReferenceGuid: '00000000-0000-0000-0000-000000000000'
    };
    
    this._memberService.creditMemberCurrency(creditStudioMemberCurrencyRequest).subscribe();
    
    const creditCardioMemberCurrencyRequest: CreditMemberCurrencyRequest = {
      MemberId: this.memberInfo.Id,
      CurrencyId: 5,
      PackId: 3,
      MenuPackId: 3,
      Amount: 1,
      StartAt: '0001-01-01 00:00:00.000000',
      EndAt: '0001-01-01 00:00:00.000000',
      Comment: 'TRIAL CREDIT',
      BookingId: null,
      BookingGuid: '00000000-0000-0000-0000-000000000000',
      BookingDate: '0001-01-01 00:00:00.000000',
      ReferenceGuid: '00000000-0000-0000-0000-000000000000'
    };
    
    this._memberService.creditMemberCurrency(creditCardioMemberCurrencyRequest).subscribe();
  }
    
  private addMemberDatumEntries() {
    const phoneDatumRequest: MemberDatum = {
      Id: 0,
      Guid: Utils.stringToGUID(''),
      CreatedAt: new Date('0001-01-01 00:00:00.000000'),
      CreatedBy: Utils.stringToGUID(this.personInfo.Guid),
      member_id: this.memberInfo.Id,
      datum_guid: Utils.stringToGUID(this.personInfo.PhoneNumber),
      type: 'phone',
      datum_text: this.personInfo.DialCode + this.personInfo.PhoneNumber
    };
    
    const emailDatumRequest: MemberDatum = {
      Id: 0,
      Guid: Utils.stringToGUID(''),
      CreatedAt: new Date('0001-01-01 00:00:00.000000'),
      CreatedBy: Utils.stringToGUID(this.personInfo.Guid),
      member_id: this.memberInfo.Id,
      datum_guid: Utils.stringToGUID(this.personInfo.Email),
      type: 'email',
      datum_text: this.personInfo.Email
    };
    
    this._memberService.addMemberDatum(phoneDatumRequest).subscribe();
    this._memberService.addMemberDatum(emailDatumRequest).subscribe();
  }
  
  private handleError(err: any) {
    this.loginStep = 'error';
    this.submitted = false;
  }

  flag(): string {
    return `https://dropfitness-static.nyc3.digitaloceanspaces.com/static/svg/flags/4x3/${this.countryCode}.svg`;
  }

  changeCountry(ctry: any) {
    this.countryCode = ctry.country;
    this.dialCode = ctry.phoneCode;
  }

  isInvalid(controlName: string): boolean {
    const control = this.profileForm.controls[controlName];
    return control.invalid && (control.dirty || control.touched);
  }

  minDOB(): string {
    let today = new Date();
    return `${today.getFullYear() - 100}-${(today.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}-${today.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}`;
  }

  maxDOB(): string {
    let today = new Date();
    return `${today.getFullYear() - 15}-${(today.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}-${today.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}`;
  }

}
