//system
import { Inject, Injectable } from '@angular/core';
import { HttpService, HttpServiceConfig } from './common/http.service';

//services
import { LogService } from './common/log.service';

//environment
import { environment } from 'src/environments/environment';

//models
import { SiteMeta } from 'src/app/models/common-models';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {

  constructor(private logService: LogService, 
    @Inject('AssetsHttpService') private httpService: HttpService) { 
    
  }

  async getSiteMeta(handle: string): Promise<SiteMeta> {
    var config: HttpServiceConfig = {
      baseUrl: `${environment.assetsBaseUrl}api/v${environment.assetsApiVersion}`
    };
    var headers = new HttpHeaders({
      'x-api-key': environment.assetsApiKey,
      'accept': 'application/json',
    });
    this.httpService.configure(config);
    var siteMeta: SiteMeta = await this.httpService.getPromise<SiteMeta>(`client/assets/${handle}`, undefined, headers);
    return siteMeta;
  }
}
