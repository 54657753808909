//system
import { Injectable } from '@angular/core';
import { HttpService, HttpServiceConfig } from './common/http.service';

//environment
import { environment } from 'src/environments/environment';

//models
import { UploadIntent } from 'src/app/models/enums';
import { Upload } from '../models/meta';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  
  constructor(private httpService: HttpService) { 
    const config: HttpServiceConfig = {
      baseUrl: `${environment.facility[environment.submerchant].facilityUrl}api/v${environment.facility[environment.submerchant].facilityApiVersion}`
    };
    this.httpService.configure(config);
  }

  getByIntent(uploadIntent: UploadIntent,intentId: number, uploadTypeSlug: string): Promise<Upload> {
    return this.httpService.getPromise<Upload>(`Upload/ByIntent/${uploadIntent}/${intentId}/${uploadTypeSlug}`);
  }
}