export enum ProductCategory {
    Unset = 0,
    Instruction = 1,
    Training = 2,
    Food = 3
}

export enum Action {
    Unset = 0,
    Add = 1,
    Remove = 2,
    Draft = 3
}

export enum Status {
    Unset = 0,
    Active = 1,
    InActive = 2,
    Draft = 3,
    Deleted = 4
}

export enum PromoCodeAction {
    Unset = 0,
    Add = 1,
    Remove = 2,
    Claim = 3,
    Expire = 4,
    Redeem = 5
}

export enum ReferralCodeAction {
    Unset = 0,
    Add = 1,
    Remove = 2,
    Claim = 3,
    Expire = 4,
    Redeem = 5
}

export enum EmailAction {
    Unset = 0,
    Add = 1,
    Remove = 2,
    Confirm = 3
}

export enum Gender {
    Unset = 0,
    Female = 1,
    Male = 2,
    NonBinary = 3,
    Other = 4
}

export enum Unit {
    Unset = 0,
    Registration = 1,
    Instruction = 2,
    Training = 3,
    Food = 4,
    Gym = 5,
    InstructionUnlimited = 6,
    GymUnlimited = 7
}

export enum Platform {
    Unset = 0,
    iOS = 1,
    Android = 2
}

export enum App {
    Unset = 0,
    MemberApp = 1,
    StaffApp = 2
}

export enum CommunicationType {
    Unset = 0,
    Email = 1,
    SMS = 2,
    PushNotification = 3
}

export enum UserState {
    Unset = 0,
    Active = 1,
    Inactive = 2,
    Draft = 3,
    Deleted = 4,
    Unavailable = 5
}

export enum UploadIntent {
    Unset = 0,
    Member = 1,
    Instructor = 2,
    Trainer = 3,
    Staff = 4,
    Facility = 5,
    Class = 6,
    Studio = 7,
    Cafe = 8,
    Currency = 9,
    CurrencyPack = 10
}

export enum TermUnit {
    Unset = 0,
    Day = 1,
    Week = 2,
    Month = 3,
    Year = 4
}

export enum LedgerAction {
    Unset = 0,
    Pending = 1,
    Queue = 2,
    Discard = 3,
    Success = 4,
    Fail = 5
}

export enum PaymentAction {
    Unset = 0,
    Pending = 1,
    Success = 2,
    Fail = 3,
    AlreadyProcessed = 4,
    Refunded = 5,
    RefundFail = 6,
    PartialRefund = 7
}

export enum RepeatFrequencyType {
    Unset = 0,
    Daily = 1,
    Weekly = 2,
    Monthly = 3,
    Yearly = 4
}

export enum User {
    Unset = 0,
    Member = 1,
    Instructor = 2,
    Trainer = 3,
    Staff = 4
}

export enum ScheduleIntent {
    Unset = 0,
    Course = 1,
    Shift = 2
}

export enum PTType {
    Unset = 0,
    Individual = 1,
    Group = 2
}

export enum PTBookingAction {
    Unset = 0,
    Book = 1,
    Cancel = 2,
    CheckIn = 3,
    NoShow = 4,
    LateCancel = 5,
    ConfirmedCheckIn = 6
}

export enum PTScheduleAction {
    Unset = 0,
    Request = 1,
    Book = 2,
    Cancel = 3,
    Reject = 4
}

export enum ClassBookingAction {
    Unset = 0,
    Book = 1,
    Cancel = 2,
    CheckIn = 3,
    NoShow = 4,
    LateCancel = 5,
    ConfirmedCheckIn = 6,
    NoShowPaymentFailed = 7,
    NoShowCharged = 8
}

export enum ClassAction {
    Unset = 0,
    Active = 1,
    Cancelled = 2
}

export enum GymCheckInState {
    Unset = 0,
    EarlyCheckIn = 1,
    FirstCheckIn = 2,
    NormalCheckIn = 3,
    AccountOnHold = 4
}

export enum GymCheckInContext {
    Unset = 0,
    ClassBooking = 1,
    PTBooking = 2,
    CWSubscription = 3,
    ClassSubscription = 4,
    PTSubscription = 5,
    FreeTrial = 6,
    OnboardingRequired = 7,
    PaymentFailed = 8,
    PausedSubscription = 9,
    CWPass = 10,
    WalkIn = 11
}

export enum GymCheckInContextColor {
    Unset = 0,
    Green = 1,
    Red = 2,
    Orange = 3
}

export enum UserType {
    Unset = 0,
    Member = 1,
    PersonalTrainer = 2,
    Instructor = 3,
    Staff = 4
}

export enum AccountAction {
    Unset = 0,
    Pending = 1,
    Deleted = 2,
    Cancel = 3
}

export enum CafeAttributeType {
    Unset = 0,
    Single = 1,
    Multiple = 2,
    AdditionSingle = 3,
    AdditionMultiple = 4
}

export enum CafeOrderState {
    Unset = 0,
    Placed = 1,
    Preparing = 2,
    Ready = 3,
    PickedUp = 4,
    Cancelled = 5,
    Rejected = 6
}

export enum CafeCategoryState {
    Unset = 0,
    Active = 1,
    Inactive = 2,
    Deleted = 3
}

export enum CafeProductState {
    Unset = 0,
    Active = 1,
    Inactive = 2,
    Unavailable = 3,
    Deleted = 4,
    Draft = 5
}

export enum CafeScheduleState {
    Unset = 0,
    Active = 1,
    Inactive = 2,
    Deleted = 3
}

export enum CafeOrderTransactionState {
    Unset = 0,
    Charged = 1,
    Refunded = 2
}

export enum MartVariantType {
    Unset = 0,
    Single = 1,
    Multiple = 2,
    AdditionSingle = 3,
    AdditionMultiple = 4
}

export enum MartOrderState {
    Unset = 0,
    Placed = 1,
    Preparing = 2,
    Ready = 3,
    Charged = 4,
    PickedUp = 5,
    InstantPickedUp = 6,
    Completed = 7,
    Cancelled = 8,
    Rejected = 9
}

export enum MartSectionState {
    Unset = 0,
    Active = 1,
    Inactive = 2,
    Deleted = 3
}

export enum MartCategoryState {
    Unset = 0,
    Active = 1,
    Inactive = 2,
    Deleted = 3
}

export enum MartProductState {
    Unset = 0,
    Active = 1,
    Inactive = 2,
    Unavailable = 3,
    StaffActive = 4,
    Deleted = 5,
    Draft = 6
}

export enum MartProductType {
    Unset = 0,
    Consumable = 1,
    VirtualCurrency = 2
}

export enum MartProductCurrencyType {
    Unset = 0,
    Pack = 1,
    Subscription = 2,
    PauseSubscription = 3
}

export enum MartProductVariantState {
    Unset = 0,
    Active = 1,
    Inactive = 2,
    Unavailable = 3,
    Deleted = 4,
    Draft = 5
}

export enum MartModifierType {
    Unset = 0,
    Single = 1,
    Multiple = 2,
    AdditionSingle = 3,
    AdditionMultiple = 4
}

export enum MartModifierValueState {
    Unset = 0,
    Active = 1,
    Inactive = 2,
    Unavailable = 3,
    Deleted = 4,
    Draft = 5
}

export enum MartOrderTransactionState {
    Unset = 0,
    Charged = 1,
    Refunded = 2
}

export enum DbAction {
    Unset = 0,
    Add = 1,
    Update = 2,
    Remove = 3
}

export enum CurrencyMenuPackState {
    Unset = 0,
    Active = 1,
    Inactive = 2,
    Deleted = 3,
    StaffActive = 4
}

export enum CurrencyCategoryState {
    Unset = 0,
    Active = 1,
    Inactive = 2,
    Deleted = 3
}

export enum SearchUnit {
    Unset = 0,
    Member = 1,
    Trainer = 2,
    Instructor = 3,
    Staff = 4,
    Classes = 5,
    Food = 6
}

export enum TagState {
    Unset = 0,
    Active = 1,
    Inactive = 2,
    Deleted = 3
}

export enum DiscountState {
    Unset = 0,
    Active = 1,
    Inactive = 2,
    Deleted = 3,
    Draft = 4
}

export enum PaymentLinkState {
    Unset = 0,
    ProcessedStripe = 1,
    ProcessedBackend = 3
}

export enum MembershipAction {
    Unset = 0,
    Add = 1,
    Remove = 2,
    PaymentFailed = 3,
    PaymentDetailsUpdated = 4,
    PaymentRetried = 5,
    PauseInitiated = 6,
    Paused = 7,
    Resumed = 8
}

export enum MemberNoteAction {
    Unset = 0,
    Add = 1,
    Remove = 2,
    Archive = 3
}

export enum ClassAttendanceState {
    Unset = 0,
    ConfirmedCheckIn = 1,
    NoShow = 2
}

export enum LateCancelContext {
    Unset = 0,
    Unlimited = 1,
    Limited = 2,
    FreeTrial = 3
}

export enum Tier {
    Unset = 0,
    Tier1 = 1,
    Tier2 = 2
}

export enum GustoUserType {
    Unset = 0,
    Instructor = 1,
    PersonalTrainer = 2,
    GroupTrainer = 3
}

export enum InviteCodeIntent {
    Unset = 0,
    ClassSession = 1,
    PTSession = 2,
    AppInvite = 3,
    MenuPack = 4
}

export enum InviteCodeRedemptionUnit {
    Unset = 0, 
    Unlimited = 1, 
    Limited = 2, 
    LimitedPerMember = 3
}

export enum InviteCodeType { 
    Unset = 0, 
    UserGenerated = 1, 
    ReEngagementFreeCredit = 2,
    PaymentLink = 3
}

export enum CurrencyCategoryType {
    Unset = 0,
    Packs = 1,
    Subscriptions = 2,
    PackSubscriptions = 3
}

export enum DiscountUnit {
    Unset = 0,
    Percentage = 1,
    Amount = 2
}

export enum UserRole {
    NotSet = 0,
    SystemAdmin = 1,
    Administrator = 2,
    CorporateManager = 3,
    CorporateEmployee = 4,
    Partner = 5,
    StudioManager = 6,
    GeneralManager = 7,
    PersonalTrainer = 8,
    Manager = 9,
    Instructor = 10,
    Staff = 11,
    Member = 12
}

export enum SessionStatus {
    Unset = 0,
    Upcoming = 1,
    Completed = 2,
    InProgress = 3,
    Cancelled = 4,
}

export enum MemberRatingIntent {
    Unset = 0,
    ClassSession = 1,
    PTSession = 2
}